.MaterialSummaryPicks {

    .bathroom-information {
        position: relative;
        top: -235px;
        width: 97%;
    }

    .general-information-outer {
        border: 1px solid;
        border-color: #000000;
    }

    .summary-header {
        position: relative;
        top: 5px;
    }

    .bathroom-information-inner {
        border: 5px solid;
        border-bottom: 2px solid;
        border-color: #e8e8e8;
        position: relative;
        top: 20px;
        margin-bottom: 25px;
    }

    .bathroom-subheader {
        position: relative;
        top: 20px;
    }

    .edit-button2 {
        width: 200px;
        height: 50px;
        background-color: #e8e8e8;
        color: #1e90ff;
        font-size: 100%;
        font-weight: bold;
        border-radius: 50px;
        position: relative;
        top: -20px;
        left: 755px;
    }

    .bathroom-information-innermost {
        border: 5px solid;
        border-top: none;
        border-color: #e8e8e8;
        position: relative;
        top: -5px;
        margin-bottom: 20px;
        padding-bottom: 25px;
    }

    .materials-flex {
        position: relative;
        top: 20px;
        padding-left: 17px;
    }

    .bathroom-information-inner2 {
        border: 5px solid;
        border-bottom: 2px solid;
        border-color: #e8e8e8;
        position: relative;
        top: 20px;
        margin-bottom: 25px;
        padding-top: 55px;
      }

      .bathroom-floor-subheader {
        position: relative;
        top: -20px;
      }

      .choicemaking-btn {
        width: 170px;
        height: 50px;
        background-color: #e8e8e8;
        color: #1e90ff;
        font-size: 100%;
        font-weight: bold;
        border-radius: 50px;
        position: relative;
        top: -10px;
    }
}