.printedForm {
  pointer-events: none;

  .page {
    margin-top: -100px;
  }

  h1 {
    font-weight: bold;
    font-size: 35px;
    margin-bottom: 120px;
    margin-top: -15px;
  }
  h2 {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 15px;
    margin-top: 30px;
  }
  h3 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 6px;
    margin-top: 30px;
  }
  textarea {
    width: 100%;
    height: 250px;
    border: 1px solid gray;
    margin-top: 8px;
  }

  label {
    padding-left: 30px;
    padding: 15px;
  }

  .print-productselection {
    //display: flex;
    //flex-wrap: wrap;

    .print-product-active {
      border: 1px solid red;
    }

    .print-productcard {
      width: 100%;
      padding: 6px 15px;
      display: flex;
      justify-content: space-between;

      .product-text {
        span {
          margin-right: 4px;
        }
      }
    }

    .product-comment {
      width: 100%;
      padding: 6px 15px;
      white-space: pre-line;
    }

    .additional-productcard {
      * {
        flex: 1 1 100%;
      }

      *:not(:first-child) {
        max-width: 20%;
      }

      *:last-child {
        text-align: right;
      }
    }
  }
}

.onlyPrint {
  visibility: hidden;
  position: fixed;
}

@media print {
  .print-page-break {
    page-break-before: always;
    height: 120px;
    width: 100%;
  }

  .onlyPrint {
    display: block !important;
    visibility: visible;
    position: initial;
  }

  @page {
    // size: 826px 1169px; // A4 in 100 PPI
    size: 1240px 1754px; // A4 in 150 PPI
  }
}
