.ProductSelectButton {
  .select-icon, .deselect-icon {
    background-color: white;
    border-radius: inherit;
    border: 2px solid white;
    height: 1rem;
    margin: 0 0.5rem 0 0.5rem;
    opacity: 0;
    width: 1rem;

    -webkit-transition: all, 0.1s linear;
    -moz-transition: all, 0.1s linear;
    -ms-transition: all, 0.1s linear;
    -o-transition: all, 0.1s linear;
    transition: all, 0.1s linear;
  }

  &:hover {
    .select-icon {
      color: #288538;
      opacity: 1;
    }
    .deselect-icon {
      color: #9B2C2C;
      opacity: 1;
    }
  }
}
