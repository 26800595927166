.ApartmentDropdown {
  position: relative;
  a,
  button {
    width: 100%;
    color: #000000;
    background-color: #fff556;
    border: none;
    padding: 1rem;
    text-align: left;
  }

  label {
    pointer-events: none;
    margin: 0px;
    align-self: center;
    font-weight: bold;
    font-size: 80%;
  }

  .button-content {
    display: flex;
    place-content: space-between;

    .icon {
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  .dropdown-content {
    z-index: 1;
    position: absolute;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    /* Hide scrollbar chrome */
    &::-webkit-scrollbar {
      display: none;
    }
    //Hide scrollbars
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    // Slide animation
    -webkit-transition: height, 0.3s linear;
    -moz-transition: height, 0.3s linear;
    -ms-transition: height, 0.3s linear;
    -o-transition: height, 0.3s linear;
    transition: height, 0.3s linear;
  }

  &:hover {
    .dropdown-content {
      max-height: 210px;
    }
  }

  &:not(:hover) {
    .dropdown-content {
      max-height: 0px;
    }
  }
}
