.ForgottenPassword {

    .main {
        margin-bottom: 12pt;
        margin-top: 12pt;
    }

    .welcome-box {
        background-color: #ffffff;
        padding-top: 42pt;
        padding-left: 42pt;
        padding-bottom: 42pt;
        padding-right: 65pt;
    }

    .logo-boxes {
        display: none, null, block;
        position: relative;

        &:before {
            position: absolute;
            width: 70px;
            height: 70px;
            background-color: #fff556;
            bottom: 0px;
            left: 70px;
        }

        &::after {
            position: absolute;
            left: -70px;
            height: 140px;
            width: 140px;
            background: #373737;
            bottom: "-140px";
        }
    }

    .input-box {
        background-color: #ffffff;
        padding-top: 42pt;
        padding-left: 42pt;
        padding-bottom: 38pt;
        padding-right: 42pt;
    }
}
