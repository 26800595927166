.Condominium {

    .heading {
        position: absolute;
        bottom: 6pt;
        left: 20pt;
    }

    .flex-menu {
        position: absolute;
        bottom: 11pt;
        left: 840pt;
    }
}