.ProductCard {
  position: relative;
  margin: 0 1rem 2rem 1rem;
  padding: 0 0 4rem 0;
  transition: 0.2s;
  outline-offset: 0.79rem;
  cursor: pointer;

  &:hover {
    outline: 1px solid rgb(48, 48, 48);
    z-index: 1;

    .product-image {
      opacity: 0.3;
    }

    button {
      opacity: 1;
    }
  }

  &:focus-within {
    outline: 1px solid rgb(48, 48, 48);

    .product-image {
      opacity: 0.3;
    }

    button {
      opacity: 1;
    }
  }

  &.selected {
    outline: 1px solid #45a455;
  }

  /* &.selected {
    outline: 1px solid #45a455;
    margin-bottom: 4rem;
    padding: 0;
  }

  &.selected:hover {
    outline: 1px solid #45a455;
    margin-bottom: 0.8rem;

    button {
      transition-delay: 0.2s;
      opacity: 1;
    }
  } */

  /* &.selected:focus-within {
    margin-bottom: 0.8rem;

    button {
      transition-delay: 0s, 0.2s;
      opacity: 1;
    }
  }
 */
  .product-span p {
    font-size: 80%;
  }

  .product-select-grid-button {
    padding: 10px;
    margin-top: 1rem;
    margin-bottom: 1.2rem;
    opacity: 0;

    // Fade
    -webkit-transition: opacity, 0.2s linear;
    -moz-transition: opacity, 0.2s linear;
    -ms-transition: opacity, 0.2s linear;
    -o-transition: opacity, 0.2s linear;
    transition: opacity, 0.2s linear;

    &:hover {
      background-color: #288538;
    }
  }

  .product-image.selected {
    opacity: 0.3;
  }

  img.product-image {
    height: 236px;
    object-fit: contain;
  }

  .product-number {
    font-weight: bold;
    font-size: 60%;
    margin-top: 0.75rem;
  }

  .contract-price {
    background-color: #fff556;
    font-weight: bold;
    font-size: 80%;
  }

  .dropdown-content {
    position: absolute;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    /* Hide scrollbar chrome */
    &::-webkit-scrollbar {
      display: none;
    }
    //Hide scrollbars
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    // Slide animation
    -webkit-transition: height, 0.2s linear;
    -moz-transition: height, 0.2s linear;
    -ms-transition: height, 0.2s linear;
    -o-transition: height, 0.2s linear;
    transition: height, 0.2s linear;
  }

  .dropdown-content.selected {
    position: absolute;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    /* Hide scrollbar chrome */
    &::-webkit-scrollbar {
      display: none;
    }
    //Hide scrollbars
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .selected-chip {
    display: none;
  }

  .selected-chip.selected {
    display: block;
  }
}
