.MaterialSummaryGeneral {

    .general-information {
        position: relative;
        top: -263px;
        width: 97%;
    }

    .general-information-outer {
        border: 1px solid;
        border-color: #000000;
    }

    .summary-header {
        position: relative;
        top: 5px;
    }

    .edit-button1{
        width: 200px;
        height: 50px;
        background-color: #e8e8e8;
        color: #1e90ff;
        font-size: 100%;
        font-weight: bold;
        border-radius: 50px;
        position: relative;
        top: -30px;
        left: 777px;
      }

      .general-information-inner {
        border: 5px solid;
        border-color: #e8e8e8;
        position: relative;
        top: 20px;
        margin-bottom: 25px;
        padding-bottom: 0px;
    }

    .answer {
        width: 500px;
        height: 16px;
    }
}