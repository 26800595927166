.MaterialFormOffer {
  position: relative;
  background: #ffffff;
  margin-bottom: 4pt;

  .heading {
      position: relative;
      top: 6px;
      font-size: 20px;
      font-weight: bold;
  }

  .submit {
      position: relative;
      left: 580px;
      top: -25px;
  }

  .closing-alert {
      position: relative;
      left: 775px;
      top: -96px;
      font-size: 15px;
      width: 27%;
      height: 100px;
  }

  .personal-grid {
      position: relative;
      top: -55px;
      width: 97%;
  }

  .personal-grid-boxes {
      width: 100%;
      height: 110%;
      background-color: #e8e8e8;
  }

  .list {
      display: flex;
      flex-direction: row;
      gap: 24px;
      position: relative;
      top: -250px;
      left: 23px;
  }

  .print-button {
    width: 200px;
    height: 50px;
    background-color: #e8e8e8;
    color: #1e90ff;
    font-size: 100%;
    font-weight: bold;
    border-radius: 50px;
    position: relative;
    top: -286px;
    left: 810px;
  }

  .edit-button1{
      width: 200px;
      height: 50px;
      background-color: #e8e8e8;
      color: #1e90ff;
      font-size: 100%;
      font-weight: bold;
      border-radius: 50px;
      position: relative;
      top: -30px;
      left: 777px;
    }

    .materials-flex {
        position: relative;
        top: 20px;
        padding-left: 17px;
    }

    .bathroom-information-inner2 {
      border: 5px solid;
      border-bottom: 2px solid;
      border-color: #e8e8e8;
      position: relative;
      top: 20px;
      margin-bottom: 25px;
      padding-top: 55px;
    }

    .bathroom-floor-subheader {
      position: relative;
      top: -20px;
    }

  .general-information {
      position: relative;
      top: -263px;
      width: 97%;
  }

  .general-information-outer {
      border: 1px solid;
      border-color: #000000;
  }

  .general-information-inner {
      border: 5px solid;
      border-color: #e8e8e8;
      position: relative;
      top: 20px;
      margin-bottom: 25px;
      padding-bottom: 0px;
  }

  .edit-button2 {
      width: 200px;
      height: 50px;
      background-color: #e8e8e8;
      color: #1e90ff;
      font-size: 100%;
      font-weight: bold;
      border-radius: 50px;
      position: relative;
      top: -20px;
      left: 755px;
  }

  .bathroom-information {
      position: relative;
      top: -235px;
      width: 97%;
  }

  .bathroom-information-inner {
      border: 5px solid;
      border-bottom: 2px solid;
      border-color: #e8e8e8;
      position: relative;
      top: 20px;
      margin-bottom: 25px;
  }

  .bathroom-subheader {
      position: relative;
      top: 20px;
  }

  .bathroom-information-innermost {
      border: 5px solid;
      border-top: none;
      border-color: #e8e8e8;
      position: relative;
      top: -5px;
      margin-bottom: 20px;
      padding-bottom: 25px;
  }

  .choicemaking-btn {
      width: 170px;
      height: 50px;
      background-color: #e8e8e8;
      color: #1e90ff;
      font-size: 100%;
      font-weight: bold;
      border-radius: 50px;
      position: relative;
      top: -10px;
  }

  .total {
      position: relative;
      top: -207px;
      width: 97%;
  }

  .total-outer {
      border: 1px solid;
      border-color: #000000;
  }

  .total-inner {
      border: 5px solid;
      border-color: #000000;
      margin-bottom: 25px;
      height: 80px;
  }

  .comment-textarea {
    color: white;

    &:focus {
      color: inherit;
    }
  }

}

.offer-title {
  padding-bottom: 12px;
}

.persons {
  width: 50%;

  .person {
    width: 50%;
  }
}

.title-container {
  padding: 15px 30px;
}

.print-offer-product {
  display: none!important;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 6px;
}


@media print {
  .print-offer-product {
    display: flex!important;
  }

  .offer-title {
    font-size: 20px;
    font-weight: bold;
  }

  .title-container {
    padding: 8px;
    padding-top: 15px;
  }

  .persons {
    display: block;
    width: 100%!important;

    .person {
      width: 33%;
    }
  }

  .info-box {
    padding-top: 15px!important;
    border: none!important;
    padding-left: 0!important;
    padding-right: 0!important;
  }

  .product-container {
    padding-top: 15px!important;
    border: none!important;
    padding-left: 0!important;
    padding-right: 0!important;

    .product-info {
      display:block;
    }

  }

}

.comment-counter {
  margin-left: 4px;
  font-family: "aria";
  font-size: 12px;
  margin-top: -1px;
}
