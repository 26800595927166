.CondominiumChoice {
  .slick-slider {
    .slick-slide {
      -webkit-transition: transform 0.5s;
      -moz-transition: transform 0.5s;
      -o-transition: transform 0.5s;
      transition: transform 0.5s;
    }

    .slick-center {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      transform: scale(1);
    }

    .slick-slide:not(.slick-center) {
      -webkit-transform: scale(0.9);
      -moz-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
}
