.print-only {
  display: none!important;
}

@media print{
  .print-hide {
    display:none!important;
  }

  .print-only {
    display: inherit!important;
  }
}
