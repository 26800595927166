.ErrorDialog {

    .error-dialog {
        position: fixed;
        top: 0;
        left: 0;
        background: salmon;
        width: 100%;
        height: 40pt;
    }

    .description {
        padding-left: 300pt;
        font-weight: bold;
        color: white;
    }

    .close-btn {
        position: absolute;
        right: 8px;
        top: 8px;
        color: black;
    }
}