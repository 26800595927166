.MaterialFormCommentModal {

    .modal-body {
        display: flex;
        margin: 3rem 1.5rem 3rem 3rem;
        padding: 0;
        flex-direction: column;
        align-items: center;
    }

    .comment-box {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    .comment-area {
        border: 2px solid lightgray;
        position: relative;
        right: 70px;
        resize: none;
        height: 250px;
        width: 550px;
    }

    .button-group {
        position: relative;
        top: 15px;
        right: 168px
    }

    .cancel-btn {
        font-weight: bold;
        width: 10rem;
        border-radius: 50px;
        background-color: #bfbfbf;
        color: white;
    }

}