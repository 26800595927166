.CustomCloseButton {
  -webkit-transition: color, 0.3s linear;
  -moz-transition: color, 0.3s linear;
  -ms-transition: color, 0.3s linear;
  -o-transition: color, 0.3s linear;
  transition: color, 0.3s linear;

  &:hover {
    background-color: lightgray;
  }
}
