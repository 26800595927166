.ImageSlider {
  max-width: 100%;
  max-height: inherit;

  .slick-slide img {
    width: inherit;
    max-width: inherit;
  }

  .slick-initialized {
    margin-bottom: 4rem;
  }

  .slick-next {
    right: 15px;
  }

  .slick-prev {
    left: 15px;
    z-index: 1;
  }

  .slick-next:before,
  .slick-prev:before {
    color: black;
  }

  .slick-dots {
    bottom: -2rem;
  }

  .slick-dots li {
    width: 6rem;
  }

  .slick-active a img {
    border: 2px solid #fff556;

    -webkit-transition: border, 0.2s linear;
    -moz-transition: border, 0.2s linear;
    -ms-transition: border, 0.2s linear;
    -o-transition: border, 0.2s linear;
    transition: border, 0.2s linear;
  }
}
